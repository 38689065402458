<template >
  <div class="faq-container d-flex flex-column" id="faq">
    <v-container class="d-flex flex-column justify-center">
      <div class="faq-title d-flex flex-row justify-center align-center text--center">
        Preguntas frecuentes
      </div>
      <div>
        <v-row
          dense
          :style="
            $vuetify.breakpoint.mdAndUp
              ? 'margin-top: 51px;'
              : 'margin-top: 51px;'
          "
          class="d-flex flex-row justify-center align-center"
        >
          <v-col
            class="d-flex flex-column justify-center align-center"
            cols="12"
            lg="6"
          >
            <div
              v-for="(panel, index) in questions"
              :key="index"
              class="panel"
              :class="{ active: activePanel === index }"
            >
              <div
                class="panel-header"
                @click="togglePanel(index)"
                :class="{ 'color-green-text': activePanel === index }"
              >
                {{ panel.question }}
                <span
                  class="chevron ml-6 ml-lg-8"
                  :class="{ rotated: activePanel === index }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <mask
                      id="mask0_626_1962"
                      style="mask-type: alpha"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect
                        x="24"
                        width="24"
                        height="24"
                        transform="rotate(90 24 0)"
                        fill="#D9D9D9"
                      />
                    </mask>
                    <g mask="url(#mask0_626_1962)">
                      <path
                        d="M2.9 7.1499C3.15 6.8999 3.44583 6.7749 3.7875 6.7749C4.12917 6.7749 4.425 6.8999 4.675 7.1499L12 14.4749L19.35 7.1249C19.5833 6.89157 19.875 6.7749 20.225 6.7749C20.575 6.7749 20.875 6.8999 21.125 7.1499C21.375 7.3999 21.5 7.69574 21.5 8.0374C21.5 8.37907 21.375 8.6749 21.125 8.9249L12.7 17.3249C12.6 17.4249 12.4917 17.4957 12.375 17.5374C12.2583 17.5791 12.1333 17.5999 12 17.5999C11.8667 17.5999 11.7417 17.5791 11.625 17.5374C11.5083 17.4957 11.4 17.4249 11.3 17.3249L2.875 8.8999C2.64167 8.66657 2.525 8.37907 2.525 8.0374C2.525 7.69573 2.65 7.3999 2.9 7.1499Z"
                        :fill="activePanel === index ? '#3BD4AE' : '#1C1B1F'"
                      />
                    </g>
                  </svg>
                </span>
              </div>
              <transition name="slide">
                <div v-if="activePanel === index" class="panel-content">
                  {{ panel.answer }}
                </div>
              </transition>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      questions: [
        {
          question: "¿Puedo pedir un viaje o hacer reservas para una persona que no esté en mi panel?",
          answer:
            "¡Sí! Al momento de solicitar un viaje por el Panel Corporativo, selecciona ''Usuario No Corporativo'', llenas los campos, procedes a pedir el viaje y listo, tu rider llegará enseguida.",
        },
        {
          question: "¿Cómo solicitar la factura previa a la recarga?",
          answer:
            "Escribe un correo a corporativo@ridery.app solicitando la factura de la recarga previamente realizada, o en su defecto, al llenar el formulario de pago selecciona ''SI'' en el campo de factura.",
        },
        {
          question: "¿Cómo cuida Ridery la seguridad de sus pasajeros?",
          answer:
            "Aseguramos un proceso robusto de certificación de conductores y vehículos, y adicionalmente, nuestro equipo de monitoreo está siempre a disposición para atender cualquier necesidad que se presente.",
        },
        {
          question: "¿Qué debo hacer si presento una incidencia en mi app?",
          answer:
            "¡Nuestros operadores están 24 horas para tí! Comunícate por los siguientes medios para cualquier incidencia o inconveniente presentado",
        },
        {
          question: "¿Cómo se calcula el precio de un viaje?",
          answer:
            "Tomamos como base una tarifa fija por flota seleccionada, sobre la cual pueden aplicar variaciones dependiendo del horario, tráfico, clima, entre otros factores.",
        },
      ],
      activePanel: -1,
    };
  },
  methods: {
    togglePanel(index) {
      if (this.activePanel === index) {
        this.activePanel = -1;
      } else {
        this.activePanel = index;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.faq {
  &-container {
    color: #000421;
    font-family: Urbanist;
    padding-top: 250px;
    padding-bottom: 150px;

    background: #f5f6f7;
  }
  &-title {
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}

.activeClass {
  .questionTitle,
  .questionIcon {
    color: #3bd4ae !important;
    transition: color 0.5s ease;
  }
}

.panel {
  width: 100%;
  // border: 1px solid #ccc;
  margin: 10px 0;
  overflow: hidden;
  // transition: max-height 0.3s ease-in-out;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: justify;

  transition: color 0.2s ease-in-out;
}

.chevron {
  transition: transform 0.3s;
}

.rotated {
  transform: rotate(180deg);
}

.panel-content {
  padding: 10px;
  text-align: justify;
}

.active {
  // background-color: #f0f0f0;
  max-height: 250px; /* Adjust as needed */
}

.slide-enter-active,
.slide-leave-active {
  // transition: max-height 0.1s ease-in-out;
}
.slide-enter,
.slide-leave-to {
  max-height: 0;
  overflow: hidden;
}

@media (max-width: 960px) {
  .faq {
    &-container {
      color: #000421;
      font-family: Urbanist;
      padding-top: 170px;
      background: #f5f6f7;
    }
    &-title {
      font-size: 60px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
  }
}
</style>