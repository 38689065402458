<template>
  <div class="panel-explained-container" id="panel-explained">
    <v-container class="d-flex flex-column">
      <v-row dense class="ma-0 pa-0 panel-explained-title">
        <v-col
          cols="12"
          class="d-flex justify-start align-start justify-lg-start"
        >
          <template v-if="$vuetify.breakpoint.lgAndUp">
            <span class="color-white-text">Nuestro</span>
          </template>
          <template v-else>
            <div>
              <span class="color-white-text">Nuestro</span>
              <span class="color-green-text"> panel</span>
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row dense class="ma-0 pa-0 panel-explained-title">
        <v-col
          cols="12"
          class="d-flex justify-start align-center justify-lg-start"
        >
          <span class="color-green-text" v-if="$vuetify.breakpoint.lgAndUp">
            panel corporativo
          </span>
          <span class="color-green-text" v-else> corporativo </span>
        </v-col>
      </v-row>
      <div class="mt-8 mt-lg-16">
        <v-row
          dense
          class="ma-0 pa-0 d-flex flex-lg-row justify-lg-space-around flex-column"
          v-for="panel in panelExplained"
          :key="panel.indexGiven"
          :class="{ 'flex-lg-row-reverse': panel.indexGiven % 2 === 0 }"
        >
          <v-col cols="12" lg="5" class="d-flex flex-row justify-center">
            <v-img
              :src="panel.image"
              :max-height="$vuetify.breakpoint.lgAndUp ? 400 : 340"
              :max-width="$vuetify.breakpoint.lgAndUp ? 550 : 340"
              contain
            />
          </v-col>
          <v-col
            cols="12"
            lg="5"
            class="d-flex flex-column justify-center align-lg-start align-center"
          >
            <div style="max-width: 95%">
              <div class="color-green-text panel-explained-row-title">
                {{ panel.title }}
              </div>
              <div class="color-white-text mt-9 panel-explained-row-copy">
                {{ panel.copy }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      panelExplained: [
        {
          indexGiven: 1,
          image: require("../assets/new_landing/final/panel_explained/exp1.png"),
          title: "Gestiona los traslados de tu empresa en cualquier momento",
          copy: "Crea o programa viajes al instante, monitorea los envíos de tu negocio y comprueba el estatus de tu presupuesto usando tu cuenta para empresas",
        },
        {
          indexGiven: 2,
          image: require("../assets/new_landing/final/panel_explained/exp2.png"),
          title: "Administra tu presupuesto en tiempo real",
          copy: "Crea facturas y estados de cuenta en segundos para llevar el control de tus gastos de movilidad sin esfuerzo alguno",
        },
        {
          indexGiven: 3,
          image: require("../assets/new_landing/panel-computer.png"),
          title: "Afilia a toda tu empresa con pocos clics",
          copy: "Agrega personas específicas o a toda tu organización con la carga masiva de nómina y asigna saldos disponibles de forma personalizada",
        },
        {
          indexGiven: 4,
          image: require("../assets/new_landing/final/panel_explained/exp4.png"),
          title: "Realiza viajes o envíos con múltiples destinos",
          copy: "Traslada a tu equipo a diferentes puntos de la ciudad o envía paquetes importantes a varios destinos en un mismo viaje con tarifas más económicas.",
        },
        // {
        //   indexGiven: 5,
        //   image: require("../assets/new_landing/final/panel_explained/exp4.png"),
        //   title: "Recarga tu balance de forma autogestionada",
        //   copy: "Agrega saldo a tu cuenta usando tarjetas de crédito, pago móvil, transferencias e incluso dólares en efectivo",
        // },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.panel-explained {
  &-container {
    background-color: #000428;
    opacity: 1;
  }
  &-title {
    font-family: Urbanist;
    font-size: 89.544px;
    font-style: normal;
    font-weight: 800;
    line-height: 90px; /* 100.509% */
  }
  &-row {
    &-title {
      // text-align: justify;
      font-family: Urbanist;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &-copy {
      text-align: justify;
      font-family: Urbanist;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 144% */
    }
  }
}

@media (max-width: 960px) {
  .panel-explained {
    &-title {
      font-family: Urbanist;
      font-size: 60px;
      font-style: normal;
      font-weight: 800;
      line-height: 80px; /* 133.333% */
    }
    &-row {
      &-title {
        text-align: justify;
        font-family: Urbanist;
        font-size: 24.02px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      &-copy {
        text-align: justify;
        font-family: Urbanist;
        font-size: 17.662px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.433px; /* 144% */
      }
    }
  }
}
</style>