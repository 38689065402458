<template>
  <v-container class="hero-container ma-0 pa-0" fluid>
    <!-- desktop -->
    <v-container v-if="$vuetify.breakpoint.lgAndUp">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" lg="6" class="ma-0 d-flex flex-column justify-center">
          <div class="hero-text-1">Transforma la movilidad de tu empresa</div>
          <div class="hero-text-2 mt-lg-8">
            Mueve a tu empresa de manera rápida, cómoda y segura, al mejor
            precio del mercado.
          </div>
          <div
            class="d-flex flex-column flex-lg-row align-center mb-8 mb-lg-0 mt-lg-8"
          >
            <v-btn
              color="primary"
              class="hero-button"
              :min-height="$vuetify.breakpoint.lgAndUp ? 56 : 50"
              min-width="240"
              :href="whatsappContact"
              target="_blank"
            >
              Contáctanos
            </v-btn>
            <v-btn
              color="success"
              class="hero-button ml-lg-7 mt-6 mt-lg-0"
              :min-height="$vuetify.breakpoint.lgAndUp ? 56 : 50"
              min-width="240"
              @click="$router.push({ name: 'contact' })"
            >
              Comienza hoy
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="ma-0 pa-0 d-flex flex-row justify-end align-center image-container"
        >
          <v-img
            src="../assets/new_landing/Hanna-web-ridery-empresas.jpg"
            class="hero-image"
            min-width="320"
            min-height="320"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="overlay"></div>
        </v-col>
      </v-row>
    </v-container>
    <!-- mobile -->
    <div v-else class="hero-container-mobile d-flex flex-column">
      <v-img style="" src="../assets/new_landing/Hanna-web-ridery-empresas-mobile.jpg">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-container style="margin-top: -65px; z-index: 1">
        <div class="hero-text-1">Transforma la movilidad de tu empresa</div>
        <div class="hero-text-2 mt-5">
          Mueve a tu empresa de manera rápida, cómoda y segura, al mejor precio
          del mercado.
        </div>
        <div class="d-flex flex-column flex-lg-row align-center mt-5">
          <v-btn
            color="primary"
            class="hero-button"
            :min-height="$vuetify.breakpoint.lgAndUp ? 56 : 56"
            min-width="240"
            :href="whatsappContact"
            target="_blank"
          >
            Contáctanos
          </v-btn>
          <v-btn
            color="success"
            class="hero-button ml-lg-7 mt-5 mt-lg-0"
            :min-height="$vuetify.breakpoint.lgAndUp ? 56 : 56"
            min-width="240"
            @click="$router.push({ name: 'contact' })"
          >
            Comienza hoy
          </v-btn>
        </div>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import { mdiArrowDown } from "@mdi/js";

export default {
  name: "Hero",
  data() {
    return {
      whatsappContact:
        "https://api.whatsapp.com/send?phone=584126042980&text=Soy%20un%20cliente%20corporativo.%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC",
    };
  },
};
</script>

<style lang="scss" scoped>
.hero {
  &-container {
    background-color: #f5f6f7;
    &-mobile {
      background-color: #ffffff;
    }
  }
  &-button {
    text-transform: none !important;
    color: #f5f6f7;
    font-family: Urbanist;
    font-size: 25.776px;
    font-style: normal;
    font-weight: 800;
    line-height: 34.368px;
    border-radius: 11px;
  }
  &-text-1 {
    color: #3bd4ae;
    font-family: Urbanist;
    font-size: 42.283px;
    font-style: normal;
    font-weight: 900;
    line-height: 44.172px; /* 104.468% */
  }
  &-text-2 {
    color: #000421;
    font-family: Urbanist;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 41.143px; /* 137.143% */
  }
  &-image {
    max-height: 100%;
    max-width: 100%;
    border-radius: 25px 25px 0px 0px;
  }
}

.image-container {
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  background: linear-gradient(
    to top left,
    rgba(255, 255, 255, 0) 70%,
    rgba(245, 246, 247, 1) 100%
  );
  z-index: 1;
  pointer-events: none;
  // border-top-left-radius: 23px;
  // border-bottom-left-radius: 25px;
  border-radius: 22px 22px 0px 0px;
}

@media (max-width: 960px) {
  .hero-text {
    color: #000421;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34.431px;
  }
  .hero-image {
    max-height: 100%;
    max-width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    // border-bottom-left-radius: 25px;
  }

  .hero {
    &-text-1 {
      color: #3bd4ae;
      font-family: Urbanist;
      font-size: 40.774px;
      font-style: normal;
      font-weight: 900;
      line-height: 40.041px; /* 98.2% */
    }
    &-text-2 {
      color: #000421;
      font-family: Urbanist;
      font-size: 24.465px;
      font-style: normal;
      font-weight: 400;
      line-height: 33.552px; /* 137.143% */
    }
  }
}
</style>

