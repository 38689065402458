<template>
  <div class="big-business-container pt-16">
    <v-container class="d-flex flex-column">
      <v-row dense class="ma-0 pa-0">
        <v-col
          class="ma-0 pa-0 d-flex flex-column align-center big-business-title"
        >
          <template v-if="$vuetify.breakpoint.lgAndUp">
            <p>Grandes empresas confían</p>
            <p>en Ridery</p>
          </template>
          <template v-else>
            <p>Grandes empresas</p>
            <p class="mt-n8">confían en Ridery</p>
          </template>
        </v-col>
      </v-row>
      <v-row dense class="ma-0 pa-0 mt-16" v-if="$vuetify.breakpoint.lgAndUp">
        <v-col
          class="ma-0 pa-0 d-flex flex-row align-center justify-space-around"
        >
          <img
            v-for="(item, index) in carouselItems"
            :key="index"
            :src="item.svgIcon"
            style="width: 15%;"
          />
        </v-col>
      </v-row>
      <v-row
        dense
        class="ma-0 pa-0"
        style="margin-top: 35px"
        v-if="!$vuetify.breakpoint.lgAndUp"
      >
        <div class="carousel-container">
          <v-window v-model="activeSlide" class="carousel-window" continuous>
            <v-window-item v-for="(item, index) in carouselItems" :key="index">
              <v-col
                cols="12"
                lg="4"
                class="d-flex flex-row justify-center align-center"
                style="height: 220px"
              >
                <img :src="item.svgIcon" style="width: 60%;" />
              </v-col>
            </v-window-item>
          </v-window>
          <div class="carousel-dots mt-6">
            <span
              v-for="(item, index) in carouselItems"
              :key="index"
              :class="{ active: activeSlide === index }"
              @click="goToSlide(index)"
            ></span>
          </div>
        </div>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "AdaptedCards",
  data() {
    return {
      activeSlide: 0,
      carouselItems: [
        {
          indexGiven: 1,
          svgIcon: require("../assets/new_landing/final/clients/forum.svg"),
        },
        {
          indexGiven: 2,
          svgIcon: require("../assets/new_landing/final/clients/lega.svg"),
        },
        {
          indexGiven: 3,
          svgIcon: require("../assets/new_landing/final/clients/zoom.svg"),
        },
        {
          indexGiven: 4,
          svgIcon: require("../assets/new_landing/final/clients/credicard.svg"),
        },
        {
          indexGiven: 5,
          svgIcon: require("../assets/new_landing/final/clients/pedidos-ya.svg"),
        },
      ],
    };
  },
  methods: {
    goToSlide(index) {
      this.activeSlide = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.big-business-container {
  background: #f0f0f0 !important;
  padding-bottom: 55px;
  // position: relative;
}

.big-business-title {
  color: #000421;
  font-family: Urbanist;
  font-size: 45px;
  font-style: normal;
  font-weight: 800;
  line-height: 54.116px;
}

@media (max-width: 960px) {
  .big-business-title {
    color: #000421;
    font-family: Urbanist;
    font-size: 35px;
    font-style: normal;
    font-weight: 800;
    line-height: 54.116px;
  }
}

.carousel-container {
  position: relative;
  min-width: 100%;
}

.carousel-window {
  height: 200; /* Adjust the height as needed */
  overflow: hidden;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}

.carousel-dots span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin: 0 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-dots span.active {
  background-color: #31d3ae;
}

.card {
  height: 190px;
  border-radius: 13.3px;
  background: #ffffff;
  max-width: 350px;
  min-width: 340px;
  padding: 22px 20px 22px 20px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  &-title {
    color: #000421;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &-copy {
    color: #000421;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
  }
}

@media (min-width: 960px) and (-moz-osx-font-smoothing: grayscale) and (min--moz-device-pixel-ratio: 1.25),
  (-webkit-font-smoothing: antialiased) and (min-resolution: 120dpi),
  (min-resolution: 1.25dppx) {
  .card {
    height: 190px;
    border-radius: 13.3px;
    background: #ffffff;
    width: 264px !important;
    max-width: 264px;
    min-width: 264px;
    padding: 22px 20px 22px 20px;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
    &-title {
      color: #000421;
      font-family: Urbanist;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &-copy {
      color: #000421;
      font-family: Urbanist;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
</style>