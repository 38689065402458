<template>
  <div class="landing-container d-flex flex-column">
    <!-- navbar -->
    <NavBar :flat="flat" />
    <!-- hero -->
    <Hero />
    <!-- transition to big business -->
    <div class="wave1-container" :style="handleStyleTransition1()">
      <svg
        v-if="$vuetify.breakpoint.lgAndUp"
        class="wave1"
        width="1450"
        height="78"
        viewBox="0 0 1450 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.57764e-05 41.064L0 149.999H1450V41.0641C1450 41.0641 1203.43 -11.9589 999.844 16.1183C974.813 19.5704 950.699 23.2718 927.062 26.9003C790.039 47.9341 669.009 66.5127 477.804 19.826C253.616 -34.9144 4.57764e-05 41.064 4.57764e-05 41.064Z"
          fill="#F0F0F0"
        />
      </svg>
      <svg
        v-else
        class="wave2"
        width="428"
        height="78"
        viewBox="0 0 428 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M428 30.3268V91.5396H0V0C39.848 1.80505 80.6704 6.65912 120.445 15.9792C246.348 45.4812 339.056 42.7042 428 30.3268Z"
          fill="#F0F0F0"
        />
      </svg>
    </div>
    <!-- big businness -->
    <BigBusiness />
    <Additionally />
    <!-- PanelExplained -->
    <PanelExplained />
    <!-- Business -->
    <Business />
    <!-- faq -->
    <FAQ />
    <!-- footer -->
    <Footer />
    <!-- fab -->
    <v-scale-transition>
      <v-btn
        class="mr-2 mb-2"
        fab
        v-show="true"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="success"
        width="65"
        height="65"
        elevation="2"
        target="_blank"
        :href="whatsappContact"
        id="whatsapp_button"
      >
        <!-- @click="toTop" -->
        <v-icon size="48">{{ icons.mdiWhatsapp }}</v-icon>
      </v-btn>
    </v-scale-transition>
  </div>
</template>

<script>
import { mdiArrowUp, mdiWhatsapp } from "@mdi/js";

import { mapActions, mapState } from "vuex";
import { setVeeLocale } from "@/utils";
import { loadLanguageAsync } from "@/plugins/i18n";

import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import BigBusiness from "./components/BigBusiness";
import Additionally from "./components/Additionally.vue";
import PanelExplained from "./components/PanelExplained.vue";
import Business from "./components/Business.vue";
import FAQ from "./components/FAQ.vue";
import Footer from "./components/Footer.vue";

// import useAppConfig from "@core/@app-config/useAppConfig";

export default {
  name: "Landing",
  components: {
    NavBar,
    Hero,
    BigBusiness,
    Additionally,
    PanelExplained,
    Business,
    FAQ,
    Footer,
  },
  data: () => ({
    fab: false,
    color: "",
    flat: false,
    icons: {
      mdiArrowUp,
      mdiWhatsapp,
    },
    contactFormOpen: false,
    whatsappContact:
      "https://api.whatsapp.com/send?phone=584126042980&text=Soy%20un%20cliente%20corporativo.%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC",
  }),
  created() {
    const top = window.scrollY || 0;
    if (top <= 60) {
      this.color = "#F5F6F7";
      this.flat = true;
    }
    this.$vuetify.theme.dark = false;

    //locale en es
    const locale = 'es'
    this.setLang(locale);
    setVeeLocale(locale);
    this.$vuetify.lang.current = locale;
    loadLanguageAsync(locale);
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "#F5F6F7";
        this.flat = false;
      } else {
        this.color = "#F5F6F7";
        this.flat = true;
      }
    },
  },
  methods: {
    ...mapActions("auth", ["setLang"]),

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.scrollY || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    handleStyleTransition1() {
      // desktop
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "";
      }
      // mobile pero no telefono
      if (
        !this.$vuetify.breakpoint.lgAndUp &&
        this.$vuetify.breakpoint.mdAndUp
      ) {
        return "margin-top: 200px";
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        return "margin-top: 95px";
      }
      return "margin-top: 135px";
    },
  },
  async mounted() {
    if (Boolean(this.$route.query.contacta)) {
      await this.$vuetify.goTo(this.$refs.download, {
        duration: 3000,
        offset: -500,
        easing: "easeInOutCubic",
      });
      // this.contactFormOpen = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.landing-container {
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.wave1-container {
  position: relative;
  z-index: 1;
}
.wave1 {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
}
.wave2 {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  // top: -60px;
}
</style>
