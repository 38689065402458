<template>
  <div
    class="business-container d-flex flex-column justify-center"
    id="business"
  >
    <div class="wave1-container">
      <svg
        v-if="$vuetify.breakpoint.lgAndUp"
        class="wave1"
        xmlns="http://www.w3.org/2000/svg"
        width="1282"
        height="103"
        viewBox="0 0 1282 103"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0V67.5402C0 67.5402 269.137 119.312 396.9 97.7429C416.458 94.441 435.452 90.9919 454.143 87.5977C577.368 65.2211 687.448 45.2315 859.555 85.5603C1057.77 132.006 1282 67.5402 1282 67.5402V0H0Z"
          fill="#000428"
        />
      </svg>
      <svg
        v-else
        class="wave1"
        xmlns="http://www.w3.org/2000/svg"
        width="334"
        height="80"
        viewBox="0 0 334 80"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M334 67.5525C322.002 70.081 309.823 72.6169 297.361 75.0664C214.588 91.335 58.2005 63.1429 0 51.4496V0H334V67.5525Z"
          fill="#000428"
        />
      </svg>
    </div>
    <v-container class="d-flex flex-column">
      <div class="business-title">
        Únete a las +1.500 empresas que utilizan el servicio como
      </div>
      <div class="d-flex flex-row justify-center mt-8">
        <v-btn
          :min-height="$vuetify.breakpoint.lgAndUp ? 56 : 56"
          min-width="205"
          color="success"
          class="business-button"
          @click="$router.push({ name: 'contact' })"
        >
          Comienza hoy
        </v-btn>
      </div>
      <!-- carruseles -->
      <div class="business-carousel">
        <v-row dense class="ma-0 pa-0 d-flex flex-column">
          <div v-if="$vuetify.breakpoint.lgAndUp">
            <div class="carousel-container">
              <v-window
                v-model="activeSlide"
                class="carousel-window"
                continuous
              >
                <v-window-item
                  v-for="(item, index) in carouselItems"
                  :key="index"
                >
                  <div
                    class="carousel-window-item d-flex flex-row justify-space-around"
                  >
                    <div
                      v-for="child in item.children"
                      :key="child.index"
                      class="business-card d-flex flex-row justify-center align-center elevation-3"
                    >
                      <v-img
                        :src="child.image"
                        max-width="150"
                        max-height="135"
                        contain
                      >
                      </v-img>
                    </div>
                  </div>
                </v-window-item>
                <!-- <v-window-item>
                  {{ item }}
                </v-window-item> -->
              </v-window>
            </div>
            <div class="carousel-dots">
              <span
                v-for="(item, index) in carouselItems"
                :key="index"
                :class="{ active: activeSlide === index }"
                @click="goToSlide(index)"
              ></span>
            </div>
          </div>
          <div v-else>
            <div class="carousel-container mt-n16">
              <v-window
                v-model="activeSlideMobile"
                class="carousel-window"
                continuous
              >
                <v-window-item
                  v-for="(item, index) in combinedChildren"
                  :key="index"
                >
                  <div
                    class="carousel-window-item d-flex justify-center align-center"
                  >
                    <div
                      class="business-card d-flex flex-row justify-center align-center elevation-3"
                    >
                      <v-img
                        :src="item.image"
                        max-width="200"
                        max-height="120"
                        contain
                      >
                      </v-img>
                    </div>
                  </div>
                </v-window-item>
              </v-window>
            </div>
          </div>
        </v-row>
      </div>
      <!-- movamos tu empresa -->
      <div class="business-movamos mt-n1 mt-lg-16">
        <v-row class="d-flex flex-column align-center flex-lg-row">
          <v-col cols="12" lg="6" class="business-movamos-title">
            <div>Movamos tu empresa y disfrutemos el viaje</div>
            <div class="mt-5 d-flex flex-row justify-center justify-lg-start">
              <v-btn
                :min-height="$vuetify.breakpoint.lgAndUp ? 56 : 56"
                min-width="205"
                color="success"
                class="business-button"
                @click="$router.push({ name: 'contact' })"
              >
                Comienza hoy
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="d-flex flex-row justify-center align-center"
          >
            <v-img
              src="../assets/new_landing/devices.png"
              :max-width="$vuetify.breakpoint.lgAndUp ? 575 : 350"
              contain
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeSlide: 0,
      activeSlideMobile: 0,
      carouselItems: [
        {
          indexGiven: 1,
          children: [
            {
              indexGiven: 1,
              image: require("../assets/new_landing/final/carrusel/sambil.svg"),
            },
            {
              indexGiven: 2,
              image: require("../assets/new_landing/final/carrusel/mercantil.svg"),
            },
            {
              indexGiven: 3,
              image: require("../assets/new_landing/final/carrusel/purolomo.svg"),
            },
            {
              indexGiven: 4,
              image: require("../assets/new_landing/final/carrusel/eg.svg"),
            },
            {
              indexGiven: 5,
              image: require("../assets/new_landing/final/carrusel/faramtodo.svg"),
            },
          ],
        },
        {
          indexGiven: 2,
          children: [
            {
              indexGiven: 1,
              image: require("../assets/new_landing/final/carrusel/veramed.svg"),
            },
            {
              indexGiven: 2,
              image: require("../assets/new_landing/final/carrusel/bancamiga.svg"),
            },
            {
              indexGiven: 3,
              image: require("../assets/new_landing/final/carrusel/bancaribe.svg"),
            },
            {
              indexGiven: 4,
              image: require("../assets/new_landing/final/carrusel/banplus.svg"),
            },
            {
              indexGiven: 5,
              image: require("../assets/new_landing/big-business/pedidos-ya.svg"),
            },
          ],
        },
      ],
    };
  },
  methods: {
    goToSlide(index) {
      this.activeSlide = index;
    },
  },
  computed: {
    combinedChildren() {
      return this.carouselItems.reduce((combinedArray, item) => {
        return combinedArray.concat(item.children);
      }, []);
    },
  },
};
</script>
<style lang="scss" scoped>
.wave1-container {
  position: relative;
  // z-index: 1;
}
.wave1 {
  width: 100%;
  height: auto;
  // position: absolute;
  // top: 0;
  // left: 0;
}

.business {
  &-container {
    background: #f5f6f7;
    color: #000421;
  }
  &-title {
    text-align: center;
    font-family: Urbanist;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-top: 130px;
  }
  &-button {
    text-transform: none !important;
    color: #f5f6f7;
    font-family: Urbanist;
    font-size: 25.776px;
    font-style: normal;
    font-weight: 800;
    line-height: 34.368px; /* 133.333% */
  }
  &-carousel {
    margin-top: 88px;
  }
  &-card {
    border-radius: 13.3px;
    background: #f0f0f0;
    // box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
    width: 250px;
    height: 91px;
  }
  &-movamos {
    margin-top: 140px;
    &-title {
      text-align: justify;
      font-family: Urbanist;
      font-size: 41.24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.carousel-container {
  position: relative;
  min-width: 100%;
}

.carousel-window {
  height: 140px; /* Adjust the height as needed */
  overflow: hidden;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}

.carousel-window-item {
  min-height: 100%;
}

.carousel-dots span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin: 0 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-dots span.active {
  background-color: #31d3ae;
}

@media (max-width: 960px) {
  .business {
    &-container {
      background: #f5f6f7;
      color: #000421;
    }
    &-title {
      text-align: center;
      font-family: Urbanist;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      margin-top: 115px;
    }
    &-button {
      text-transform: none !important;
      color: #f5f6f7;
      font-family: Urbanist;
      font-size: 25.776px;
      font-style: normal;
      font-weight: 800;
      line-height: 34.368px; /* 133.333% */
    }
    &-card {
      border-radius: 13.3px;
      background: #f0f0f0;
      // box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
      min-width: 250px;
      height: 91px;
    }
    &-movamos {
      &-title {
        text-align: justify;
        font-family: Urbanist;
        font-size: 33px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}

@media (min-width: 960px) and (-moz-osx-font-smoothing: grayscale) and (min--moz-device-pixel-ratio: 1.25),
  (-webkit-font-smoothing: antialiased) and (min-resolution: 120dpi),
  (min-resolution: 1.25dppx) {
  .business {
    &-card {
      width: 200px;
      height: 91px;
    }
  }

  .carousel-window-item {
    justify-content: space-between;
  }
}
</style>