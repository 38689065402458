<template>
  <div class="additionally-container">
    <v-container class="d-flex flex-column">
      <!-- Simplifica tus traslados corporativos -->
      <v-row
        dense
        class="
          ma-0
          pa-0
          d-flex
          flex-column flex-lg-row
          align-center
          justify-center
        "
      >
        <v-col cols="12" lg="6">
          <div class="additionally-simplify-title mt-10 mt-lg-0">
            Simplifica tus traslados corporativos
          </div>
          <div class="additionally-simplify-text mt-lg-6 mt-6">
            Crea múltiples viajes para tu equipo, traslada cualquier documento
            con envíos y gestiona todo el presupuesto de transporte al mismo
            tiempo.
          </div>
        </v-col>

        <v-col cols="12" lg="6">
          <v-img
            src="../assets/new_landing/final/flota.png"
            :max-height="$vuetify.breakpoint.lgAndUp ? 500 : 300"
            contain
          ></v-img>
        </v-col>
      </v-row>
      <!-- a la medida de tu empresa -->
      <v-row dense class="ma-0 pa-0">
        <v-col cols="12" class="d-flex justify-center align-center">
          <div class="additionally-fit-business">
            Creado a la medida para tu empresa
          </div>
        </v-col>
      </v-row>
      <!-- tarjetas -->
      <v-row dense class="ma-0 pa-0 mt-0 mt-lg-10">
        <v-col
          lg="4"
          cols="12"
          v-for="(card, index) in cards"
          :key="index"
          class="d-flex justify-center align-center"
        >
          <div class="card mt-lg-0 mt-10 pa-6 d-flex flex-column">
            <v-img :src="card.svgIcon" alt="" max-width="40" max-height="40" />
            <div class="card-title mt-4">
              {{ card.title }}
            </div>
            <div class="card-message mt-4">
              {{ card.message }}
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- adicionalmente -->
      <v-row dense class="ma-0 pa-0 mt-10 mt-lg-16 mb-10 mb-lg-10">
        <v-col cols="12" class="d-flex flex-row justify-center">
          <div class="additionally-carousel-title">Adicionalmente</div>
        </v-col>
      </v-row>
      <!-- carrusel -->
      <v-row
        dense
        class="ma-0 pa-0 d-flex flex-column"
        style="margin-top: 25px"
      >
        <div class="carousel-container">
          <v-window v-model="activeSlide" class="carousel-window" continuous>
            <v-window-item
              v-for="(item, index) in carouselItems"
              :key="index"
              class="carousel-window-item"
            >
              <v-row style="min-height: 100%" class="d-flex">
                <v-col
                  cols="12"
                  lg="6"
                  class="d-flex flex-row justify-center align-center"
                >
                  <div
                    class="d-flex flex-column justify-center"
                    :style="$vuetify.breakpoint.lgAndUp && 'max-width: 80%'"
                  >
                    <template v-if="index === 0">
                      <div class="carousel-copy">
                        Por tu seguridad, todo conductor de la flota
                        <b>Ridery</b> pasa por un arduo proceso de certificación
                        que contempla 4 etapas:
                      </div>
                      <div class="carousel-ul mt-8">
                        <ul>
                          <li>Evaluación psicotécnica integral</li>
                          <li class="mt-2">
                            Revisión estética y mecánica del vehículo
                          </li>
                          <li class="mt-2">
                            Comprobación de documentos legales al día
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template v-else-if="index === 1">
                      <div class="carousel-copy">
                        Para tu comodidad, contamos con una extensa flota de
                        vehículos, desde una moto hasta vans de pasajeros
                        dispuestas para trasladarte por todo el territorio
                        nacional.
                      </div>
                    </template>
                    <template v-else-if="index === 2">
                      <div class="carousel-copy">
                        Para mayor efectividad, monitoreamos, acompañamos y
                        asignamos viajes 24/7, con un tiempo de respuesta entre
                        3 y 7 minutos aproximadamente.
                      </div>
                    </template>
                    <div
                      class="
                        mt-8
                        carousel-more-information
                        d-flex
                        flex-row
                        align-center
                      "
                      @click="$router.push({ name: 'contact' })"
                      style="cursor: pointer"
                    >
                      Más información
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        class="ml-1"
                      >
                        <mask
                          id="mask0_626_2143"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        >
                          <rect width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_626_2143)">
                          <path
                            d="M11.3 19.2998C11.1167 19.1165 11.0208 18.8831 11.0125 18.5998C11.0042 18.3165 11.0917 18.0831 11.275 17.8998L16.175 12.9998H5C4.71667 12.9998 4.47917 12.904 4.2875 12.7123C4.09583 12.5206 4 12.2831 4 11.9998C4 11.7165 4.09583 11.479 4.2875 11.2873C4.47917 11.0956 4.71667 10.9998 5 10.9998H16.175L11.275 6.0998C11.0917 5.91647 11.0042 5.68314 11.0125 5.3998C11.0208 5.11647 11.1167 4.88314 11.3 4.6998C11.4833 4.51647 11.7167 4.4248 12 4.4248C12.2833 4.4248 12.5167 4.51647 12.7 4.6998L19.3 11.2998C19.4 11.3831 19.4708 11.4873 19.5125 11.6123C19.5542 11.7373 19.575 11.8665 19.575 11.9998C19.575 12.1331 19.5542 12.2581 19.5125 12.3748C19.4708 12.4915 19.4 12.5998 19.3 12.6998L12.7 19.2998C12.5167 19.4831 12.2833 19.5748 12 19.5748C11.7167 19.5748 11.4833 19.4831 11.3 19.2998Z"
                            fill="#000421"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  class="d-flex flex-row justify-center align-center"
                  :style="$vuetify.breakpoint.lgAndUp && 'max-width: 80%'"
                >
                  <template v-if="index === 0">
                    <v-img
                      class="carousel-image-image"
                      src="../assets/new_landing/final/additionally/add1.png"
                      :max-width="$vuetify.breakpoint.lgAndUp ? 492 : 285.994"
                      :max-height="$vuetify.breakpoint.lgAndUp ? 437 : 285.994"
                      contain
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </template>
                  <template v-else-if="index === 1">
                    <v-img
                      class="carousel-image-image"
                      src="../assets/new_landing/final/additionally/add2.png"
                      :max-width="$vuetify.breakpoint.lgAndUp ? 492 : 285.994"
                      :max-height="$vuetify.breakpoint.lgAndUp ? 437 : 285.994"
                      contain
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </template>
                  <template v-else-if="index === 2">
                    <v-img
                      class="carousel-image-image"
                      src="../assets/new_landing/final/additionally/add3.png"
                      :max-width="$vuetify.breakpoint.lgAndUp ? 492 : 285.994"
                      :max-height="$vuetify.breakpoint.lgAndUp ? 437 : 285.994"
                      contain
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </template>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </div>
        <div class="carousel-dots">
          <span
            v-for="(item, index) in carouselItems"
            :key="index"
            :class="{ active: activeSlide === index }"
            @click="goToSlide(index)"
          ></span>
        </div>
      </v-row>
      <!-- Donde encontrarnos -->
      <v-row dense class="ma-0 pa-0 mt-16 mt-lg-16">
        <v-col cols="12" class="d-flex flex-row justify-start">
          <div class="additionally-where">
            Dónde <span class="color-green-text">encontrarnos</span>
          </div>
        </v-col>
      </v-row>
      <!-- mapa -->
      <v-row
        dense
        class="ma-0 pa-0 mt-16 mt-lg-16 d-flex flex-row align-center"
      >
        <v-col lg="5" cols="12" class="d-flex flex-column justify-start">
          <div
            v-for="location in locations"
            :key="location.indexGiven"
            class="d-flex flex-row align-center additionally-where-locations"
            :class="{ 'mt-4': location.indexGiven !== 1 }"
          >
            <span
              class="pb-2"
              :style="{
                fontSize: handlelocationFontSize(location)['state'],
              }"
              >{{ location.state }} /</span
            >
            <span
              class="pb-2 ml-1 color-green-text"
              :style="{
                fontSize: handlelocationFontSize(location)['capital'],
              }"
              >{{ location.capital }}</span
            >
            <span class="ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43"
                height="19"
                viewBox="0 0 43 19"
                fill="none"
              >
                <path
                  d="M18.8082 4.76835H18.799C18.6679 4.56694 18.4824 4.39897 18.2601 4.28017L10.2217 0.167883C10.0022 0.0577703 9.75546 0 9.50459 0C9.25372 0 9.00697 0.0577703 8.78753 0.167883L0.749099 4.26362C0.522124 4.37978 0.333054 4.54818 0.201024 4.7518C0.201024 4.75289 0.200788 4.75396 0.200329 4.75496C0.19987 4.75597 0.199197 4.75688 0.198349 4.75765C0.1975 4.75842 0.196493 4.75903 0.195385 4.75944C0.194277 4.75986 0.193089 4.76007 0.19189 4.76007V4.77662C0.0639989 4.97182 -0.00234905 5.19504 6.35261e-05 5.42201V13.5804C0.000525378 13.8162 0.0701127 14.0478 0.201714 14.2513C0.333315 14.4548 0.522212 14.623 0.749099 14.7388L8.78753 18.8345C8.9899 18.9345 9.2149 18.9911 9.44521 19H9.5731C9.8003 18.9899 10.022 18.9333 10.2217 18.8345L18.2601 14.7388C18.4846 14.6213 18.6712 14.4525 18.801 14.2492C18.9309 14.0459 18.9995 13.8153 19 13.5804V5.42201C19.0013 5.19255 18.9351 4.96692 18.8082 4.76835ZM9.50002 1.32627L16.7894 5.03312L13.9851 6.47284L6.62263 2.79081L9.50002 1.32627ZM9.58223 8.73997L2.2289 5.03312L5.12456 3.56031L12.4962 7.24234L9.58223 8.73997ZM1.4616 6.15842L8.85147 9.89009L8.77839 17.3121L1.4616 13.5804V6.15842ZM10.2399 17.3038L10.313 9.89009L13.2452 8.37591V11.5284C13.2452 11.7039 13.3222 11.8723 13.4592 11.9964C13.5963 12.1206 13.7822 12.1903 13.976 12.1903C14.1698 12.1903 14.3556 12.1206 14.4927 11.9964C14.6297 11.8723 14.7067 11.7039 14.7067 11.5284V7.62295L17.5384 6.16669V13.5804L10.2399 17.3038Z"
                  fill="#000421"
                />
                <path
                  d="M28.1153 15V15.9423C28.1153 16.2361 28.0124 16.4858 27.8065 16.6915C27.6006 16.8972 27.3507 17 27.0566 17C26.7624 17 26.5128 16.8972 26.3077 16.6915C26.1026 16.4858 26 16.2361 26 15.9423V8.88847C26 8.78462 26.0067 8.68077 26.0202 8.57692C26.0337 8.47307 26.0563 8.37361 26.0881 8.27855L27.8789 3.21538C27.9987 2.85528 28.2159 2.56269 28.5305 2.33763C28.8451 2.11254 29.1971 2 29.5865 2H39.4135C39.8029 2 40.155 2.11254 40.4695 2.33763C40.7841 2.56269 41.0013 2.85528 41.1211 3.21538L42.9118 8.27855C42.9437 8.37361 42.9663 8.47307 42.9798 8.57692C42.9933 8.68077 43 8.78462 43 8.88847V15.9423C43 16.2361 42.8971 16.4858 42.6912 16.6915C42.4853 16.8972 42.2353 17 41.9412 17C41.6471 17 41.3975 16.8972 41.1923 16.6915C40.9872 16.4858 40.8847 16.2361 40.8847 15.9423V15H28.1153ZM28.1077 7.07697H40.8923L39.7077 3.70193C39.6821 3.63781 39.6436 3.58813 39.5923 3.55288C39.541 3.51761 39.4801 3.49998 39.4096 3.49998H29.5904C29.5199 3.49998 29.459 3.51761 29.4077 3.55288C29.3564 3.58813 29.3179 3.63781 29.2923 3.70193L28.1077 7.07697ZM29.9638 12.3462C30.3277 12.3462 30.6362 12.2188 30.8894 11.9641C31.1426 11.7094 31.2692 11.4001 31.2692 11.0362C31.2692 10.6723 31.1419 10.3638 30.8871 10.1106C30.6324 9.85738 30.3231 9.73078 29.9593 9.73078C29.5954 9.73078 29.2869 9.85813 29.0336 10.1129C28.7804 10.3676 28.6538 10.6769 28.6538 11.0407C28.6538 11.4046 28.7812 11.7131 29.0359 11.9664C29.2906 12.2196 29.5999 12.3462 29.9638 12.3462ZM39.0407 12.3462C39.4046 12.3462 39.7131 12.2188 39.9664 11.9641C40.2196 11.7094 40.3462 11.4001 40.3462 11.0362C40.3462 10.6723 40.2188 10.3638 39.9641 10.1106C39.7094 9.85738 39.4001 9.73078 39.0362 9.73078C38.6723 9.73078 38.3638 9.85813 38.1106 10.1129C37.8574 10.3676 37.7308 10.6769 37.7308 11.0407C37.7308 11.4046 37.8581 11.7131 38.1129 11.9664C38.3676 12.2196 38.6769 12.3462 39.0407 12.3462ZM27.5 13.5H41.5V8.57692H27.5V13.5Z"
                  fill="#000421"
                />
              </svg>
            </span>
          </div>
        </v-col>
        <v-col
          lg="7"
          cols="12"
          v-if="$vuetify.breakpoint.lgAndUp"
          class="d-flex justify-center"
        >
          <v-img src="@/assets/landing/mapa-2.svg" alt="">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row> </template
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <div class="wave1-container">
      <svg
        v-if="$vuetify.breakpoint.lgAndUp"
        class="wave1"
        xmlns="http://www.w3.org/2000/svg"
        width="1450"
        height="98"
        viewBox="0 0 1450 98"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M449.613 55.8825C304.882 89.9612 0 8.16237 0 8.16237V98.949H1450V9.04353C1424.37 18.8538 1185.88 105.976 973.715 36.634C778.749 -27.0855 654.05 4.49812 514.459 39.8532L514.458 39.8535C493.285 45.2162 471.769 50.6657 449.613 55.8825Z"
          fill="#000428"
          fill-opacity="1"
        />
      </svg>
      <svg
        v-else
        class="wave1"
        xmlns="http://www.w3.org/2000/svg"
        width="520"
        height="98"
        viewBox="0 0 520 98"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M449.613 55.8825C304.882 89.9612 0 8.16237 0 8.16237V98.949H1450V9.04353C1424.37 18.8538 1185.88 105.976 973.715 36.634C778.749 -27.0855 654.05 4.49812 514.459 39.8532L514.458 39.8535C493.285 45.2162 471.769 50.6657 449.613 55.8825Z"
          fill="#000428"
          fill-opacity="1"
        />
        <!-- fill="#000421D1" -->
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "Additionally",
  data() {
    return {
      cards: [
        {
          svgIcon: require("../assets/cards/wheel.svg"),
          title: "Conductores certificados en cada viaje",
          message:
            "Certificamos a todos nuestros conductores para garantizar la máxima seguridad en los traslados de tu empresa",
        },
        {
          svgIcon: require("../assets/cards/clock.svg"),
          title: "Disponibilidad inmediata",
          message:
            "Desde motos hasta vans de pasajeros. Accede a más de 20.000 conductores disponibles para llevarte a dónde tu operación lo requiera",
        },
        {
          svgIcon: require("../assets/cards/monitor.svg"),
          title: "Acompañamiento personalizado",
          message:
            "Nuestro equipo hace seguimiento en tiempo real para atender las necesidades de tu empresa en cualquier momento",
        },
      ],
      activeSlide: 0,
      carouselItems: [
        {
          indexGiven: 1,
          image: require("../assets/add/sheet-1.png"),
        },
        {
          indexGiven: 2,
          image: require("../assets/add/sheet-1.png"),
        },
        {
          indexGiven: 3,
          image: require("../assets/add/sheet-1.png"),
        },
      ],
      locations: [
        {
          indexGiven: 1,
          state: "Distrito Capital",
          capital: "Caracas",
          parcel: true,
          rides: true,
        },
        {
          indexGiven: 3,
          state: "Miranda",
          capital:
            "Guarenas – Guatire – Los Teques – San Antonio – Cúa – Charallave",
          parcel: true,
          rides: true,
          font: "mini",
        },
        {
          indexGiven: 3,
          state: "La Guaira",
          capital: "La Guaira",
          parcel: true,
          rides: true,
        },
        {
          indexGiven: 3,
          state: "Aragua",
          capital: "Maracay – La Victoria",
          parcel: true,
          rides: true,
        },
        {
          indexGiven: 4,
          state: "Carabobo",
          capital: "Valencia - Pto. Cabello",
          parcel: true,
          rides: true,
        },
        {
          indexGiven: 5,
          state: "Lara",
          capital: "Barquisimeto",
          parcel: true,
          rides: true,
        },
        {
          indexGiven: 5,
          state: "Portuguesa",
          capital: "Acarigua – Araure",
          parcel: true,
          rides: true,
        },
        {
          indexGiven: 6,
          state: "Zulia",
          capital: "Maracaibo - Cabimas",
          parcel: true,
          rides: true,
        },
        {
          indexGiven: 7,
          state: "Nueva Esparta",
          capital: "Margarita-Porlamar",
          parcel: true,
          rides: true,
        },
        {
          indexGiven: 8,
          state: "Anzoátegui",
          capital: "Puerto la Cruz – El Tigre – Lechería",
          parcel: true,
          rides: true,
          font: "mid",
        },
        {
          indexGiven: 9,
          state: "Bolívar",
          capital: " Puerto Ordaz – Ciudad Guayana – Ciudad Bolívar",
          parcel: true,
          rides: true,
          font: "mid",
        },
        {
          indexGiven: 10,
          state: "Mérida",
          capital: "Mérida – El Vigía",
          parcel: true,
          rides: true,
        },
        {
          indexGiven: 11,
          state: "Barinas",
          capital: "Barinas",
          parcel: true,
          rides: true,
        },
        {
          indexGiven: 12,
          state: "Táchira",
          capital: "San Cristóbal",
          parcel: true,
          rides: true,
        },
        {
          indexGiven: 12,
          state: "Monagas",
          capital: "Maturín",
          parcel: true,
          rides: true,
        },
      ],
    };
  },
  methods: {
    goToSlide(index) {
      this.activeSlide = index;
    },
    handlelocationFontSize(location) {
      const { font } = location;
      if (!font) {
        return { state: "16px", capital: "15px" };
      }

      if (font === "mid") {
        return { state: "15px", capital: "14px" };
      }

      if (font === "mini") {
        return { state: "15px", capital: "11px" };
      }
      return { state: "16px", capital: "15px" };
    },
  },
};
</script>
<style lang="scss" scoped>
.additionally {
  &-container {
    background: #f0f0f0 !important;
    padding-top: 70px;
    color: #000421;
  }
  &-simplify {
    &-title {
      font-family: Urbanist;
      font-size: 38px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    &-text {
      text-align: justify;
      font-family: Urbanist;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 144% */
    }
  }
  &-fit-business {
    font-family: Urbanist;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 72.775px; /* 181.937% */

    margin-top: 100px;
  }
  &-carousel {
    &-title {
      color: #31d3ae;
      font-family: Urbanist;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 62px; /* 155% */
    }
  }
  &-where {
    color: #000421;
    font-family: Urbanist;
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: 80px; /* 133.333% */
    &-locations {
      color: #000421;
      font-family: Urbanist;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.card {
  width: 90%;
  max-width: 500px;
  height: 248px;
  border-radius: 13.3px;
  background: #f0f0f0;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  color: #000421;
  font-family: Urbanist !important;
  &-title {
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &-message {
    color: #000421;
    text-align: justify;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 143.75% */
  }
}

.carousel-container {
  position: relative;
  min-width: 100%;
}

.carousel-window {
  height: 520px; /* Adjust the height as needed */
  overflow: hidden;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}

.carousel-window-item {
  min-height: 100%;
}

.carousel-dots span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin: 0 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-dots span.active {
  background-color: #31d3ae;
}

.carousel-image {
  &-shadow {
    border-radius: 25px;
    background: #31d3ae;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    max-width: 492px;
    max-height: auto;
    width: 100%;
    height: auto;
    position: relative;
  }
  &-image {
    position: aboslute;
    top: -20px;
    right: -20px;
  }
}

.carousel-copy {
  color: #000421;
  text-align: justify;
  font-family: Urbanist;
  font-size: 24.828px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.carousel-ul {
  color: #000421;
  text-align: justify;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  li {
    position: relative;
    line-height: 1.5;
  }

  li:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #31d3ae; /* Change this to your desired color */
    border-radius: 50%; /* To make it a circle */
    position: absolute;
    left: -20px; /* Adjust this value to align the circle properly */
    top: 50%;
    transform: translateY(-50%);
  }
}

.carousel-more-information {
  color: #000421;
  text-align: justify;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.wave1-container {
  position: relative;
  z-index: 1;
  padding-top: 150px;
}
.wave1 {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
}
.wave2 {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 960px) {
  .wave1-container {
    position: relative;
    z-index: 1;
    padding-top: 90px;
  }
  .carousel-window {
    height: 600px; /* Adjust the height as needed */
    overflow: hidden;
  }
  .additionally {
    &-container {
      padding-top: 10px;
    }
    &-simplify {
      &-title {
        font-family: Urbanist;
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
      &-text {
        text-align: justify;
        font-family: Urbanist;
        font-size: 18.268px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.305px; /* 144% */
      }
    }
    &-fit-business {
      font-family: Urbanist;
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;

      margin-top: 70px;
    }
    &-carousel-title {
      color: #31d3ae;
      font-family: Urbanist;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 62px; /* 155% */
    }
    &-where {
      color: #000421;
      font-family: Urbanist;
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      line-height: 70px;
      text-align: justify;
      &-locations {
        color: #000421;
        font-family: Urbanist;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }

  .card {
    &-title {
      font-size: 17.45px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &-message {
      font-size: 15.511px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.297px; /* 143.75% */
    }
  }

  .carousel-copy {
    color: #000421;
    text-align: justify;
    font-family: Urbanist;
    font-size: 18.865px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .carousel-image {
    &-shadow {
      border-radius: 15px;
      background: #31d3ae;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      max-width: 285.994px;
      max-height: auto;
      width: 100%;
      height: auto;
      position: relative;
    }
    &-image {
      position: aboslute;
      top: -10px;
      right: -10px;
    }
  }

  .carousel-ul {
    color: #000421;
    text-align: justify;
    font-family: Urbanist;
    font-size: 15.197px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    ul {
      list-style-type: none;
      padding-left: 20px;
    }

    li {
      position: relative;
      line-height: 1.5;
    }

    li:before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      background-color: #31d3ae; /* Change this to your desired color */
      border-radius: 50%; /* To make it a circle */
      position: absolute;
      left: -20px; /* Adjust this value to align the circle properly */
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
